import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { object, shape } from 'prop-types';

import { Action, Paragraph, Separator, Subtitle, TextBlock, TextString, Title } from 'ui/components';
import { Fold, Helmet, Layout } from 'ui/partials';
import { breakpoint } from 'ui/settings';
import { setSpace, setType } from 'ui/mixins';

const Clients = styled.ul`
  ${setType('x')};
  ${breakpoint.desktopUp} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const Aside = styled.aside`
  ${breakpoint.phone} {
    text-align: center;
  }
`;
const Client = styled.li`
  & > p,
  & > a {
    ${setSpace('mtm')};
  }
  ${breakpoint.phone} {
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
    text-align: center;
    &:not(:last-child) {
      ${setSpace('mbh')};
    }
  }
  ${breakpoint.tablet} {
    ${setSpace('mbh')};
  }
  ${breakpoint.desktop} {
    ${setSpace('mbh')};
    ${setSpace('prh')};
    ${setSpace('pbl')};
    ${setSpace('mtl')};
    flex: 0 0 ${100 / 2}%;
  }
  ${breakpoint.hdesktopUp} {
    ${setSpace('prl')};
    ${setSpace('pbl')};
    ${setSpace('mtl')};
    flex: 0 0 ${100 / 3}%;
  }
`;

export default function ClientsPage(props) {
  const { data } = props;

  const localTheme = typeof window !== `undefined` ? window.localStorage.getItem('theme') : 'light';
  const theme = localTheme
    ? localTheme
    : window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

  const darkMode = theme === 'dark';

  const logos = {
    meedan: darkMode
      ? getImage(data.meedanLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.meedanLogo.edges[0].node.childImageSharp),
    bbc: darkMode
      ? getImage(data.bbcLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.bbcLogo.edges[0].node.childImageSharp),
    aj: darkMode
      ? getImage(data.ajLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.ajLogo.edges[0].node.childImageSharp),
    trint: darkMode
      ? getImage(data.trintLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.trintLogo.edges[0].node.childImageSharp),
    swisscom: darkMode
      ? getImage(data.swisscomLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.swisscomLogo.edges[0].node.childImageSharp),
    hearken: darkMode
      ? getImage(data.hearkenLogoNegative.edges[0].node.childImageSharp)
      : getImage(data.hearkenLogo.edges[0].node.childImageSharp),
  };

  console.log({ logos });

  return (
    <Fragment>
      <Helmet {...props} title="Clients" />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">Selected Clients</TextString>
              <TextString looks="h1">Entrusted by some outstanding teams</TextString>
            </Title>
            <Paragraph looks="p2">
              Over the past decade I’ve had pleasure to learn from some extraordinary teams delivering fantastic
              products for a variety of companies and organisations.
            </Paragraph>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        <Clients>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.meedan} alt="Meedan" />
            </Subtitle>
            <Paragraph looks="p4">
              Together with{' '}
              <Action href="https://biffud.com" rel="noreferrer" target="_blank" plain>
                Bad Idea Factory
              </Action>
              , I helped{' '}
              <Action href="https://meedan.com" rel="noreferrer" target="_blank" plain>
                Meedan
              </Action>{' '}
              evolve their line of fact-checking and media annotation tools.
            </Paragraph>
            <Action
              button
              plain
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              text="Ask me anything"
              obfuscated></Action>
          </Client>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.hearken} alt="Hearken" />
            </Subtitle>
            <Paragraph looks="p4">
              I helped{' '}
              <Action href="https://wearehearken.com" rel="noreferrer" target="_blank" plain>
                Hearken
              </Action>{' '}
              establish user interface design processes and evolve their Engagement Management System.
            </Paragraph>
            <Action
              button
              plain
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              text="Ask me anything"
              obfuscated></Action>
          </Client>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.bbc} alt="BBC" />
            </Subtitle>
            <Paragraph looks="p4">
              Together with{' '}
              <Action href="https://biffud.com" rel="noreferrer" target="_blank" plain>
                Bad Idea Factory
              </Action>
              , I helped BBC replace burned-in video captions with web-based accessible narrative text.
            </Paragraph>
            <Action
              button
              plain
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              text="Ask me anything"
              obfuscated></Action>
          </Client>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.aj} alt="Al Jazeera" />
            </Subtitle>
            <Paragraph looks="p4">
              I helped Al Jazeera create a set of tools for composing, editing and publishing interviews in form of
              interactive chats.
            </Paragraph>
            <Action to="/made/interviewjs" button plain>
              Learn more
            </Action>
          </Client>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.trint} alt="Trint" />
            </Subtitle>
            <Paragraph looks="p4">
              I helped Trint design, build and successfully launch its innovative transcription & media captioning
              suite.
            </Paragraph>
            <Action to="/made/trint" button plain>
              Learn more
            </Action>
          </Client>
          <Client>
            <Subtitle>
              <GatsbyImage image={logos.swisscom} alt="Swisscom" />
            </Subtitle>
            <Paragraph looks="p4">
              As part of{' '}
              <Action href="https://interactivethings.com" rel="noreferrer" target="_blank" plain>
                Interactive Things
              </Action>
              , I helped Swisscom build its cost overview and management app for mobile devices.
            </Paragraph>
            <Action to="/made/swisscom-cockpit" button plain>
              Learn more
            </Action>
          </Client>
        </Clients>
        <Separator ornamental size="h" />
        <Aside>
          <TextBlock>
            <Paragraph looks="p5">
              Other direct and indirect past engagements include: <em>Ableton</em>, <em>Neue Zürcher Zeitung</em>,{' '}
              <em>The National Museum in Warsaw</em>, <em>The KARTA Center Foundation</em>, <em>Traffic Design</em>,{' '}
              <em>Streetmix</em>, <em>Hyperaudio</em>, <em>jPlayer</em>, <em>Bad Idea Factory</em>, <em>Neurotone</em>,{' '}
              <em>PWC</em>, <em>UPC</em>, <em>Mérieux NutriSciences</em>, <em>Democracy Earth</em>,{' '}
              <em>Zurich University of the Arts</em>, <em>Pedagogical University of Krakow</em>, <em>Dada</em>,{' '}
              <em>Namesco</em>, <em>European University Institute</em>, <em>Vodeno</em>, <em>UBS</em> and more.
            </Paragraph>
          </TextBlock>
        </Aside>
      </Layout>
    </Fragment>
  );
}

ClientsPage.propTypes = {
  data: shape({
    ajLogo: object.isRequired,
    swisscomLogo: object.isRequired,
    trintLogo: object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ClientsPageQuery {
    ajLogo: allFile(filter: { relativePath: { eq: "assets/logos/aljazeera.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 118, height: 40)
          }
        }
      }
    }
    ajLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/aljazeera-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 118, height: 40)
          }
        }
      }
    }
    swisscomLogo: allFile(filter: { relativePath: { eq: "assets/logos/swisscom.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 141, height: 40)
          }
        }
      }
    }
    swisscomLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/swisscom-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 141, height: 40)
          }
        }
      }
    }
    meedanLogo: allFile(filter: { relativePath: { eq: "assets/logos/meedan.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 52, height: 40)
          }
        }
      }
    }
    meedanLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/meedan-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 52, height: 40)
          }
        }
      }
    }
    hearkenLogo: allFile(filter: { relativePath: { eq: "assets/logos/hearken.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 182, height: 40)
          }
        }
      }
    }
    hearkenLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/hearken-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 182, height: 40)
          }
        }
      }
    }
    bbcLogo: allFile(filter: { relativePath: { eq: "assets/logos/bbc.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 107, height: 40)
          }
        }
      }
    }
    bbcLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/bbc-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 107, height: 40)
          }
        }
      }
    }
    trintLogo: allFile(filter: { relativePath: { eq: "assets/logos/trint.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 88, height: 40)
          }
        }
      }
    }
    trintLogoNegative: allFile(filter: { relativePath: { eq: "assets/logos/trint-negative.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 88, height: 40)
          }
        }
      }
    }
  }
`;
